import React, { ComponentProps } from "react";
import useFilterSearch from "../../../vehicle-search/hooks/useFilterSearch";
import SelectField from "./SelectField";
import { SelectItem } from "./SingleOptionSelect";
import MultiOptionSelect from "./MultiOptionSelect";

type Props = {
  values: SelectItem[];
  options: SelectItem[];
  onChange: (value: string, checked: boolean) => void;
  resetOnBlur?: boolean;
} & Omit<ComponentProps<typeof SelectField>, "value" | "children">;

const SearchableMultiOptionSelect: React.FC<Props> = ({ values, options, onChange, resetOnBlur, ...rest }) => {
  const [searchInput, setSearchInput, filteredOptions] = useFilterSearch(options);

  return (
    <MultiOptionSelect
      options={filteredOptions}
      values={values}
      search={{
        value: searchInput,
        setValue: setSearchInput,
        resetOnBlur,
      }}
      onChange={onChange}
      {...rest}
    />
  );
};

export default SearchableMultiOptionSelect;
