export enum STORAGE_KEYS {
  DESIGN_SETTING = "DESIGN_SETTING",
  PREVIEW = "PREVIEW",
  COOKIES_FLAG = "COOKIES_FLAG",
  REDIRECT_URL = "REDIRECT_URL",
  SESSION_TOKENS = "SESSION_TOKENS",
  COMPARISON_LIST = "COMPARISON_LIST",
  FAVORITES_LIST = "FAVORITES_LIST",
  LAST_VIEWED_LIST = "LAST_VIEWED_LIST",
  FFU_TEST_DRIVE_BASIC_DATA = "FFU_TEST_DRIVE_BASIC_DATA",
  FFU_TEST_DRIVE_YOUR_DATA = "FFU_TEST_DRIVE_YOUR_DATA",
  FFU_LEASING_LEASING_CALCULATION_DATA = "FFU_LEASING_LEASING_CALCULATION_DATA",
  FFU_LEASING_REQUEST_YOUR_DATA_FORM_DATA = "FFU_LEASING_REQUEST_YOUR_DATA_FORM_DATA",
  FFU_LEASING_APPLICATION_YOUR_DATA_FORM_DATA = "FFU_LEASING_APPLICATION_YOUR_DATA_FORM_DATA",
  FFU_LEASING_APPLICATION_HOME_DELIVERY_FORM_DATA = "FFU_LEASING_APPLICATION_HOME_DELIVERY_FORM_DATA",
  FFU_VEHICLE_PURCHASE_YOUR_DATA_FORM_DATA = "FFU_VEHICLE_PURCHASE_YOUR_DATA_FORM_DATA",
  FFU_VEHICLE_PURCHASE_HOME_DELIVERY_FORM_DATA = "FFU_VEHICLE_PURCHASE_HOME_DELIVERY_FORM_DATA",
  FFU_GENERAL_INQUIRY_DATA = "FFU_GENERAL_INQUIRY_DATA",
  FFU_VEHICLE_EVALUATION_BASIC_DATA_ESTIMATION = "FFU_VEHICLE_EVALUATION_BASIC_DATA_ESTIMATION",
  FFU_VEHICLE_EVALUATION_BASIC_DATA_EVALUATION = "FFU_VEHICLE_EVALUATION_BASIC_DATA_EVALUATION",
  FFU_VEHICLE_EVALUATION_DEALER_DATA = "FFU_VEHICLE_EVALUATION_DEALER_DATA",
  FFU_VEHICLE_EVALUATION_YOUR_DATA = "FFU_VEHICLE_EVALUATION_YOUR_DATA",
  COMPETITION = "COMPETITION",
  COMPETITION_DATA_FORM_DATA = "COMPETITION_DATA_FORM_DATA",
  CI_MODE_ACTIVE = "CI_MODE_ACTIVE",
  CAMPAIGN = "CAMPAIGN",
  AD_CLICKS = "AD_CLICKS",
  USER_TOKEN = "USER_TOKEN",
  PWA_BANNER_FLAG = "PWA_BANNER_FLAG",
  NEWSLETTER_SUBSCRIBE_YOUR_DATA = "NEWSLETTER_SUBSCRIBE_YOUR_DATA",
  LOCATION = "LOCATION",
  MATCHMAKER_ANSWERS = "MATCHMAKER_ANSWERS",
  HEATMAP_EXPIRATION_TIMESTAMP_MS = "HEATMAP_EXPIRATION_TIMESTAMP_MS",
  FFU_TEST_DRIVE_MARKETPLACE_VEHICLE_DATA = "FFU_TEST_DRIVE_MARKETPLACE_VEHICLE_DATA",
  FFU_TEST_DRIVE_MARKETPLACE_DEALER_DATA = "FFU_TEST_DRIVE_MARKETPLACE_DEALER_DATA",
  FFU_TEST_DRIVE_MARKETPLACE_BASIC_DATA = "FFU_TEST_DRIVE_MARKETPLACE_BASIC_DATA",
  FFU_TEST_DRIVE_MARKETPLACE_YOUR_DATA = "FFU_TEST_DRIVE_MARKETPLACE_YOUR_DATA",
  FFU_NEW_VEHICLE_INQUIRY_DEALER_DATA = "FFU_NEW_VEHICLE_INQUIRY_DEALER_DATA",
  FFU_NEW_VEHICLE_INQUIRY_INQUIRY_DATA = "FFU_NEW_VEHICLE_INQUIRY_INQUIRY_DATA",
}

export const FFU_STORAGE_KEYS_TO_BE_CLEARED_ON_EXPLICIT_LOGOUT = [
  STORAGE_KEYS.FFU_TEST_DRIVE_BASIC_DATA,
  STORAGE_KEYS.FFU_TEST_DRIVE_YOUR_DATA,
  STORAGE_KEYS.FFU_LEASING_LEASING_CALCULATION_DATA,
  STORAGE_KEYS.FFU_LEASING_REQUEST_YOUR_DATA_FORM_DATA,
  STORAGE_KEYS.FFU_LEASING_APPLICATION_YOUR_DATA_FORM_DATA,
  STORAGE_KEYS.FFU_LEASING_APPLICATION_HOME_DELIVERY_FORM_DATA,
  STORAGE_KEYS.FFU_VEHICLE_PURCHASE_YOUR_DATA_FORM_DATA,
  STORAGE_KEYS.FFU_VEHICLE_PURCHASE_HOME_DELIVERY_FORM_DATA,
  STORAGE_KEYS.FFU_GENERAL_INQUIRY_DATA,
  STORAGE_KEYS.FFU_VEHICLE_EVALUATION_BASIC_DATA_ESTIMATION,
  STORAGE_KEYS.FFU_VEHICLE_EVALUATION_BASIC_DATA_EVALUATION,
  STORAGE_KEYS.FFU_VEHICLE_EVALUATION_DEALER_DATA,
  STORAGE_KEYS.FFU_VEHICLE_EVALUATION_YOUR_DATA,
  STORAGE_KEYS.NEWSLETTER_SUBSCRIBE_YOUR_DATA,
];
