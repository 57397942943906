import { StringParam, useQueryParam, useQueryParams } from "use-query-params";
import { useEffect, useState } from "react";
import { useGetAdsQuery } from "../../hygraph/services/adApi";
import { filterAdTargetTile, filterGeoTarget, filterQueryParam, pickRandomAd } from "../../common/helpers/ad";
import { Ad } from "../../hygraph/vo";
import { useGeoPopup } from "../../geo/services/useGeoPopup";
import { Coordinates } from "../../geo/helpers/distance";
import { GEO_QUERY_PARAM } from "../services/filterDefinition";
import { getGeoFilterTuple } from "../services/filter-types/geoFilterType";
import { notNil } from "../../common/helpers/isNil";
import useGetIpGeoLocation from "../../geo/hooks/useGetIpGeoLocation";
import useHygraphLanguage from "../../hygraph/hooks/useHygraphLanguage";
import { useAllVehicleSearchQueryParams } from "../../algolia/hooks/useVehicleSearchData";

const useReferenceLocation = (ipGeoLocation: Coordinates | null): Coordinates | null => {
  const geoFilter = useQueryParam(GEO_QUERY_PARAM, StringParam)[0];
  const { geoLocation } = useGeoPopup();

  if (geoFilter) {
    const geo = getGeoFilterTuple(geoFilter);
    if (geo[0] && geo[1]) {
      return { lat: Number(geo[0]), lng: Number(geo[1]) };
    }
  }

  if (geoLocation) {
    return { lat: Number(geoLocation.lat), lng: Number(geoLocation.lng) };
  }

  return ipGeoLocation ?? null;
};

const useFilteredAds = (ads: Ad[] | undefined | null, referenceLocation: Coordinates | null) => {
  const allQueryParams = useAllVehicleSearchQueryParams();
  const [queryParams] = useQueryParams(allQueryParams);

  return ads
    ? ads
        .filter(filterAdTargetTile)
        .filter(ad => filterGeoTarget(ad, referenceLocation))
        .filter(ad => filterQueryParam("brands", ad, queryParams))
        .filter(ad => filterQueryParam("models", ad, queryParams))
        .filter(ad => filterQueryParam("bodyTypeGroups", ad, queryParams))
        .filter(ad => filterQueryParam("vehicleConditions", ad, queryParams))
        .filter(ad => filterQueryParam("fuelTypeGroups", ad, queryParams))
    : [];
};

const useSeeds = (queryID: string | undefined, adIndexes: number[]) => {
  const [seeds, setSeeds] = useState<number[] | null>(null);

  useEffect(
    function updateSeeds() {
      if (queryID) {
        const newSeeds = adIndexes.map(_ => Math.random());
        setSeeds(newSeeds);
      }
    },
    [queryID, adIndexes],
  );

  return seeds;
};

const useSearchAds = (queryID: string, hasAds: boolean, adIndexes: number[]) => {
  const currentLanguage = useHygraphLanguage();
  const { data: ads, isFetching: isFetchingAds } = useGetAdsQuery({ currentLanguage }, { skip: !hasAds });

  const { ipGeoLocation, isFetching: isFetchingIpGeoLocation } = useGetIpGeoLocation({ skip: !hasAds });
  const referenceLocation = useReferenceLocation(ipGeoLocation);

  const filteredAds = useFilteredAds(ads, referenceLocation);

  const seeds = useSeeds(queryID, adIndexes);

  const isFetching = isFetchingAds || isFetchingIpGeoLocation;
  const readyToPickAds = !isFetching && notNil(ads) && notNil(seeds);

  const searchAds = readyToPickAds ? (seeds.map(seed => pickRandomAd(filteredAds, seed)).filter(notNil) as Ad[]) : null;

  return searchAds;
};

export default useSearchAds;
