import { useLocation } from "react-router-dom";
import { HistoryState } from "../constants/State";

const useHistoryState = () => {
  const { state } = useLocation();
  const historyState = state as undefined | Record<string, number>;

  const priorSteps = historyState?.[HistoryState.ON_CLOSE_GO_N_BACK];

  return { historyState, priorSteps };
};

export default useHistoryState;
