import React, { ReactNode } from "react";
import i18next from "i18next";
import cardSlideEmptyWireframeLight from "../../../template/private/assets/img/layout/card_slider_empty.jpg";
import cardSlideEmptyWireframeDark from "../../../template/private/assets/img/layout/card_slider_empty_dark.webp";
import cardSlideEmptyLowResLight from "../../../template/private/assets/img/layout/card_slider_lowres.jpg";
import cardSlideEmptyLowResDark from "../../../template/private/assets/img/layout/card_slider_lowres_dark.webp";
import { useDarkMode } from "../../../core/darkMode";

export type VehiclesCarouselEmptyBackground = "LowRes" | "Wireframe";

type Props = {
  children: ReactNode;
  text?: string;
  background?: VehiclesCarouselEmptyBackground;
};

const VehiclesCarouselEmpty = ({ text, background = "Wireframe", children }: Props) => {
  const darkMode = useDarkMode();

  const cardSlideEmptyLowRes = darkMode ? cardSlideEmptyLowResDark : cardSlideEmptyLowResLight;
  const cardSlideEmptyWireframe = darkMode ? cardSlideEmptyWireframeDark : cardSlideEmptyWireframeLight;

  return (
    <div className="section-carousel--empty">
      <p className="section-carousel__location">{`${i18next.t("LOCATION", { count: 1 })}:`}</p>
      <div className="section-carousel-empty-wrap">
        <div className="section-carousel__info">
          {text ? <p className="section-carousel__text">{text}</p> : null}
          {children}
        </div>
        <div className="section-carousel-empty-img-wrap">
          <picture>
            <img
              alt="box-teaser img"
              className="img section-carousel-empty__img"
              src={background === "LowRes" ? cardSlideEmptyLowRes : cardSlideEmptyWireframe}
            />
          </picture>
        </div>
      </div>
      <div className="section-carousel__btn">{i18next.t("VIEW ALL VEHICLES NEARBY")}</div>
    </div>
  );
};

export default VehiclesCarouselEmpty;
