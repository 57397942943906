import React, { useState } from "react";
import i18next from "i18next";
import classNames from "classnames";
import { getFormattedWorkingUntilLabel } from "../../dealer/services/getFormattedWorkingUntilLabel";
import useFormatter from "../../common/hooks/useFormatter";
import ContextLink from "../../common/components/ContextLink";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import getGoogleMapsJourneyLink from "../helpers/getGoogleMapsJourneyLink";
import Image, {
  IMAGE_FILL_COLORS,
  IMAGE_FIT_TYPES,
  IMAGE_TRANSFORM_TYPES,
} from "../../visual-components/components/Image";
import { hasOpeningHours } from "../../dealer/services/openingHours";
import GarageRating from "../../visual-components/components/GarageRating";
import useGeneralInquiryLink from "../../../processes/general-inquiry/hooks/useGeneralInquiryLink";
import { ButtonAnchor, ButtonLink } from "../../visual-components/components/Button";
import { DealerDetail } from "../../dealer/types/dealer";
import { getDealerPhone } from "../../dealer/services/getDealerPhone";
import getDealerDisplayName from "../../dealer/services/getDealerDisplayName";
import { useAaConversionTrackEvent } from "../../algolia/hooks/useAaConversionTrackEvent";
import { ConversionEventName } from "../../algolia/constants/ConversionEventName";
import { Employee, Maybe } from "../../hygraph/vo";
import portraitPlaceholderSvg from "../../../template/private/assets/img/layout/portrait-placeholder.svg";
import {
  outboundClickTrack,
  OutboundTrackingVehicle,
} from "../../analytics/scripts/navigationTrack/outboundClickTrack";
import { OutboundClickType } from "../../analytics/constants/OutboundClickType";
import { useAttributeConversionToAds } from "../../analytics/hooks/useAttributeConversionToAds";

const DealerLogo: React.FC<{ name: string; baseSrc: string }> = ({ name, baseSrc }) => (
  <Image
    contain
    alt={name}
    baseSrc={baseSrc}
    fillColor={IMAGE_FILL_COLORS.white}
    fit={IMAGE_FIT_TYPES.fill}
    imageSizes={IMAGE_TRANSFORM_TYPES.dealerLogo}
  />
);

const GarageLogo: React.FC<{
  name: string;
  logoPictureUrl?: Maybe<string>;
  hasDealerDetailLink: boolean;
  dealerDetailTo: string;
}> = ({ name, logoPictureUrl, hasDealerDetailLink, dealerDetailTo }) => (
  <div className="garage__logo">
    {logoPictureUrl ? (
      <>
        {hasDealerDetailLink ? (
          <ContextLink to={dealerDetailTo}>
            <DealerLogo baseSrc={logoPictureUrl} name={name} />
          </ContextLink>
        ) : (
          <DealerLogo baseSrc={logoPictureUrl} name={name} />
        )}
      </>
    ) : null}
  </div>
);

type Props = {
  teaser: string | null | undefined;
  dealer: DealerDetail;
  vehicleId: number;
  outboundTrackingVehicle: OutboundTrackingVehicle;
  hideDealerProfileLink?: boolean;
  salesAdvisor?: Maybe<Employee>;
};

const DealerInfo: React.FC<Props> = ({
  dealer,
  salesAdvisor,
  teaser,
  vehicleId,
  outboundTrackingVehicle,
  hideDealerProfileLink = false,
}) => {
  const { formatTime } = useFormatter();
  const [open, setOpen] = useState<boolean>(false);
  let openingText = "";
  if (dealer && hasOpeningHours(dealer.openingHours)) {
    openingText = getFormattedWorkingUntilLabel(dealer.openingHours, dealer.workingHoursExceptions ?? [], formatTime);
  }

  const { dealerDetailPath } = usePathBuilder();
  const insideId = dealer.insideId;
  const dealerDetailTo = dealerDetailPath({ slug: dealer.slug, id: insideId });
  const hasDealerDetailLink = !hideDealerProfileLink && !!insideId;

  const buildGeneralInquiryLink = useGeneralInquiryLink();

  const dealerPhone = getDealerPhone({ phone: dealer.phone, calltrackingPhone: dealer.calltrackingPhone });

  const aaConversionTrackEvent = useAaConversionTrackEvent();
  const attributeConversionToAds = useAttributeConversionToAds();

  return (
    <section
      className={classNames("detail__section  detail__section--garage detail__section--collapsable", {
        open,
        "detail__section--garage-only": !salesAdvisor,
      })}
    >
      {salesAdvisor ? (
        <>
          <div className="garage__portrait">
            {salesAdvisor?.profilePictureUrl ? (
              <Image
                alt={`${salesAdvisor.firstname} ${salesAdvisor.name}`}
                baseSrc={salesAdvisor.profilePictureUrl}
                className="garage-portrait__img"
                fit={IMAGE_FIT_TYPES.crop}
                imageSizes={IMAGE_TRANSFORM_TYPES.employeePictureSquare}
              />
            ) : (
              <img alt="" src={portraitPlaceholderSvg} />
            )}
          </div>
          <div className="garage__name">
            <div className="garage-name__title">
              {salesAdvisor.firstname} {salesAdvisor.name}
            </div>
            <p className="garage-name__text">
              {i18next.t("YOUR CONTACT PERSON AT GARAGE X", {
                value: getDealerDisplayName(dealer),
              })}
            </p>
          </div>
        </>
      ) : (
        <div className="garage__only--mobile">
          <GarageLogo
            dealerDetailTo={dealerDetailTo}
            hasDealerDetailLink={hasDealerDetailLink}
            logoPictureUrl={dealer.logoPictureUrl}
            name={dealer.name}
          />
          <p>
            <strong>{getDealerDisplayName(dealer)}</strong>
          </p>
          <p>
            <strong>
              {dealer.zip} {dealer.city}
            </strong>
          </p>
        </div>
      )}
      {teaser ? <div className="garage__quotation">{teaser}</div> : null}
      <div className="btn-row btn-row--contact">
        <ButtonLink
          className="garage__mail"
          icon="mail"
          {...buildGeneralInquiryLink({ slug: dealer.slug, id: insideId, vehicleId })}
          onClick={() => {
            aaConversionTrackEvent(insideId, ConversionEventName.startVehicleLead);
          }}
        >
          {i18next.t("INQUIRE")}
        </ButtonLink>

        {dealerPhone ? (
          <ButtonAnchor
            transparent
            className="garage__phone"
            href={`tel:${dealerPhone}`}
            icon="phone"
            mobileText={i18next.t("CALL")}
            onClick={(ev: React.MouseEvent<HTMLSpanElement>) => {
              aaConversionTrackEvent(insideId, ConversionEventName.startVehicleLead);
              outboundClickTrack({
                ev,
                dealer,
                outboundLinkType: OutboundClickType.phone,
                vehicle: outboundTrackingVehicle,
                calltrackingSubscriberId: dealer.calltrackingSubscriberId,
                attributeConversionToAds,
              });
            }}
          >
            {dealerPhone}
          </ButtonAnchor>
        ) : null}
      </div>
      <div className="garage__profil">
        <GarageLogo
          dealerDetailTo={dealerDetailTo}
          hasDealerDetailLink={hasDealerDetailLink}
          logoPictureUrl={dealer.logoPictureUrl}
          name={dealer.name}
        />
        <div className="garage__info">
          <div className="garage__address">
            {getDealerDisplayName(dealer)}
            <br />
            {dealer.street}
            <br />
            {dealer.zip} {dealer.city}
            <br />
            <span className="garage-address__subtext">{openingText}</span>
          </div>
          {dealer.googleReviews?.userRatingsTotal && dealer.googleReviews.rating ? (
            <GarageRating rating={dealer.googleReviews.rating} reviewCount={dealer.googleReviews.userRatingsTotal} />
          ) : null}
        </div>
      </div>
      <div className="btn-row btn-row--profil">
        {!hideDealerProfileLink && insideId ? (
          <ContextLink
            className="btn transparent"
            to={dealerDetailTo}
            onClick={() => {
              aaConversionTrackEvent(insideId, ConversionEventName.startVehicleLead);
            }}
          >
            <span className="ifont ifont--info " />
            <span className="btn__text">{i18next.t("SHOW DEALER PROFILE")}</span>
          </ContextLink>
        ) : null}
        {dealer ? (
          <ButtonAnchor
            transparent
            icon="location"
            rel="noreferrer"
            target="_blank"
            href={getGoogleMapsJourneyLink({
              name: dealer.name,
              street: dealer.street,
              zip: dealer.zip,
              city: dealer.city,
            })}
            onClick={ev => {
              aaConversionTrackEvent(insideId, ConversionEventName.startVehicleLead);
              outboundClickTrack({
                ev,
                dealer,
                outboundLinkType: OutboundClickType.routefinder,
                attributeConversionToAds,
              });
            }}
          >
            {i18next.t("PLAN JOURNEY")}
          </ButtonAnchor>
        ) : null}
      </div>
      <button className="garage__toggle" onClick={() => setOpen(!open)}>
        <span className="ifont ifont--angle_down" />
        <span className="garage__show--more">{i18next.t("SHOW MORE")}</span>
        <span className="garage__show--less">{i18next.t("SHOW LESS")}</span>
      </button>
    </section>
  );
};

export default DealerInfo;
