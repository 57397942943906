import { ReactNode, useEffect } from "react";
import { differenceInDays } from "date-fns";
import useLocalStorage from "../../common/hooks/useLocalStorage";
import { STORAGE_KEYS } from "../../common/constants/Storage";
import { AdClick } from "../types/AdClick";

const ATTRIBUTION_PURGE_DAYS = 7;

function StaleAdClicksCleaner(): ReactNode {
  const [persistedAds, setPersistedAds] = useLocalStorage<AdClick[]>(STORAGE_KEYS.AD_CLICKS, []);

  useEffect(() => {
    const now = new Date();
    const validAds = persistedAds.filter(ad => differenceInDays(now, ad.timestamp) < ATTRIBUTION_PURGE_DAYS);

    if (validAds.length < persistedAds.length) {
      setPersistedAds(validAds);
    }
  }, [persistedAds, setPersistedAds]);

  return null;
}

export default StaleAdClicksCleaner;
