import React, { ReactNode } from "react";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { Provider } from "react-redux";
import { QueryParamProvider } from "use-query-params";
import { HelmetProvider } from "react-helmet-async";
import AppErrorBoundary from "./modules/common/components/AppErrorBoundary";
import AuthStateHandler from "./auth/components/AuthStateHandler";
import NotificationProvider from "./modules/notification/components/NotificationProvider";
import { store } from "./core/state/init/store";
import { LanguageChangeUrlBuilderContextProvider } from "./modules/layout/hooks/useLanguageChange";
import { FormatterInstanceProvider } from "./modules/common/hooks/useFormatter";
import LanguageGuard from "./core/i18next/components/LanguageGuard";
import CompetitionStateHandler from "./processes/tour/components/CompetitionStateHandler";
import CampaignHandler from "./modules/campaign/components/CampaignHandler";
import { UserIdProvider } from "./modules/analytics/hooks/useUserId";
import ViewedVehiclesHandler from "./modules/vehicle/components/ViewedVehiclesHandler";
import { LinkContextRootProvider } from "./modules/common/components/ContextLink";
import StaleAdClicksCleaner from "./modules/analytics/components/StaleAdClicksCleaner";

/**
 * Reusable AppComponent - used by whitelabel and main app
 * @param children
 * @param errorBoundarySiblings Components that should be rendered outside of the error boundary (will remain mounted when error occurs)
 * @constructor
 */
function App({ children, errorBoundarySiblings }: { children: ReactNode; errorBoundarySiblings?: ReactNode }) {
  return (
    <Provider store={store}>
      {/* Use replaceIn to prevent invalid back state when having vehicle search on dealer profile */}
      {/* Use batching to prevent overwriting prior transactions on vehicle search (e.g. query reappliance, geo filter) */}
      <QueryParamProvider adapter={ReactRouter6Adapter} options={{ updateType: "replaceIn", enableBatching: true }}>
        <FormatterInstanceProvider>
          <HelmetProvider>
            <UserIdProvider>
              <LanguageChangeUrlBuilderContextProvider>
                <NotificationProvider>
                  <LanguageGuard>
                    <AppErrorBoundary>
                      <LinkContextRootProvider>
                        <AuthStateHandler />
                        <div id="dialog-root" />
                        {children}
                        <CompetitionStateHandler />
                        <CampaignHandler />
                        <ViewedVehiclesHandler />
                        <StaleAdClicksCleaner />
                      </LinkContextRootProvider>
                    </AppErrorBoundary>
                    {errorBoundarySiblings}
                  </LanguageGuard>
                </NotificationProvider>
              </LanguageChangeUrlBuilderContextProvider>
            </UserIdProvider>
          </HelmetProvider>
        </FormatterInstanceProvider>
      </QueryParamProvider>
    </Provider>
  );
}

export default App;
