import React from "react";
import i18next from "i18next";
import { AugmentedDetailVehicle } from "../types/vehicle";
import { CURRENCY_LABELS, DEFAULT_CURRENCY } from "../../common/constants/Currency";
import useFormatter from "../../common/hooks/useFormatter";
import offersOnlineSales from "../services/offersOnlineSales";
import { addToCartTrack } from "../../analytics/scripts/addToCartTrack/addToCartTrack";
import { ConversionEventName } from "../../algolia/constants/ConversionEventName";
import { useAaConversionTrackEvent } from "../../algolia/hooks/useAaConversionTrackEvent";
import useVehiclePurchaseLink from "../../../processes/vehicle-purchase/hooks/useVehiclePurchaseLink";
import useTestDriveLink from "../../../processes/test-drive/hooks/useTestDriveLink";
import ContextLink from "../../common/components/ContextLink";
import { useAttributeConversionToAds } from "../../analytics/hooks/useAttributeConversionToAds";
import { OldPrice } from "./CarPriceInfo";
import DetailHeroTileWrap from "./DetailHeroTileWrap";

type Props = {
  vehicle: AugmentedDetailVehicle;
};

const useTileMeta = (vehicle: AugmentedDetailVehicle) => {
  const buildPurchaseVehicleLink = useVehiclePurchaseLink();
  const buildTestDriveLink = useTestDriveLink();
  const aaConversionTrackEvent = useAaConversionTrackEvent();
  const permitOnlineSales = offersOnlineSales(vehicle);
  const attributeConversionToAds = useAttributeConversionToAds();

  return permitOnlineSales
    ? {
        ...buildPurchaseVehicleLink({ slug: vehicle.slug, id: vehicle.vehicleId }),
        onClick: () => {
          addToCartTrack(vehicle, attributeConversionToAds);
          aaConversionTrackEvent(vehicle.dealer.insideId, ConversionEventName.startVehicleLead);
        },
        icon: "cart_small",
        label: i18next.t("BUY ONLINE"),
      }
    : {
        ...buildTestDriveLink({ slug: vehicle.slug, id: vehicle.vehicleId }),
        onClick: () => {
          addToCartTrack(vehicle, attributeConversionToAds);
          aaConversionTrackEvent(vehicle.vehicleId, ConversionEventName.startVehicleLead);
        },
        icon: "calendar",
        label: i18next.t("TEST DRIVE"),
      };
};

const DetailHeroLeftTile: React.FC<Props> = ({ vehicle }) => {
  const { formatCurrency, formatCurrencyWithoutSuffix } = useFormatter();
  const tileMeta = useTileMeta(vehicle);

  const labelId = `label-price-tile`;

  return (
    <DetailHeroTileWrap reserved={vehicle.reserved}>
      <ContextLink
        aria-labelledby={labelId}
        className="price-tile__link"
        rel={tileMeta.rel}
        state={tileMeta.state}
        to={tileMeta.to}
        onClick={tileMeta.onClick}
      />
      <div className="price-tile-inner">
        <p className="price-tile__label">
          {i18next.t("BUY PRICE")} {CURRENCY_LABELS[vehicle.currency ?? DEFAULT_CURRENCY]}
        </p>
        <p className="price-tile__price">{formatCurrencyWithoutSuffix(vehicle.priceB2cGross)}</p>
        <OldPrice
          brand={vehicle.brand?.name}
          className="price-tile__text-sml"
          currency={vehicle.currency}
          priceB2cGross={vehicle.priceB2cGross}
          priceInitialB2cGross={vehicle.priceInitialB2cGross}
          pricePreparationGross={vehicle.pricePreparationGross}
        />
        {vehicle.pricePreparationGross && vehicle.pricePreparationGross > 0 ? (
          <p className="price-tile__meta">
            {i18next.t("PRICE INCLUDING DELIVERY FEE", {
              fee: formatCurrency(vehicle.pricePreparationGross, vehicle.currency),
            })}
          </p>
        ) : null}
      </div>
      <div className="price-tile__btn" id={labelId}>
        <span className={`ifont ifont--${tileMeta.icon}`} />
        {tileMeta.label}
      </div>
    </DetailHeroTileWrap>
  );
};

export default DetailHeroLeftTile;
