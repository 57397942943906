import { useLayoutEffect } from "react";
import { FEATURE_FLAG_DARK_MODE } from "../modules/common/constants/configuration";
import { STORAGE_KEYS } from "../modules/common/constants/Storage";
import useLocalStorage from "../modules/common/hooks/useLocalStorage";

const DARK_MODE_CLASS = "dark-mode";

export enum DESIGN_MODE {
  auto = "auto",
  light = "light",
  dark = "dark",
}

const systemSettingDarkMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

export const useDesignMode = () => useLocalStorage(STORAGE_KEYS.DESIGN_SETTING, DESIGN_MODE.auto);

const isDarkMode = (designMode: DESIGN_MODE): boolean => {
  switch (designMode) {
    case "dark":
      return true;
    case "auto":
      return systemSettingDarkMediaQuery.matches;
    case "light":
    default:
      return false;
  }
};

export const useDarkMode = () => {
  const [designMode] = useDesignMode();
  return isDarkMode(designMode);
};

const setDarkModeClass = (darkMode: boolean) => {
  if (FEATURE_FLAG_DARK_MODE && darkMode) {
    document.documentElement.classList.add(DARK_MODE_CLASS);
  } else {
    document.documentElement.classList.remove(DARK_MODE_CLASS);
  }
};
export const useInitDarkMode = () => {
  const darkMode = useDarkMode();
  useLayoutEffect(() => setDarkModeClass(darkMode), [darkMode]);
};
