import React from "react";
import i18next from "i18next";
import { ArrayParam } from "use-query-params";
import SearchableMultiOptionSelect from "../../../visual-components/components/form/SearchableMultiOptionSelect";
import { FilterItemReferenceData, FilterReferenceData } from "../../services/reference-data-aggregator/types";
import {
  getBrandModelCount,
  MODEL_BRAND_SEPARATOR,
  MODEL_FACET_KEY,
} from "../../services/filter-types/brandModelFilterTypeHelpers";
import useFilterQueryParam from "../../hooks/useFilterQueryParam";
import { VehicleSearchResponse } from "../../../algolia/services/vehicleSearchApi";
import { getOccurrenceFor } from "../../services/getOccurrenceFor";
import FilterBlock from "./FilterBlock";

const FilterBox = ({
  modelBrandQuery,
  setModelBrand,
  brandId,
  models: modelData,
  searchData,
}: {
  modelBrandQuery: string;
  setModelBrand: (query: string | null) => void;
  brandId: number;
  models: Record<string, FilterItemReferenceData>;
  searchData: VehicleSearchResponse | undefined;
}) => {
  const queryParts = modelBrandQuery.split(MODEL_BRAND_SEPARATOR);
  const count = getBrandModelCount(queryParts);

  const modelIds = queryParts.slice(1);

  const models = modelData[brandId]?.list || [];
  const modelMap = modelData[brandId]?.map || {};
  const modelsWithInactive = models.map(({ value, name }) => {
    return {
      value,
      name,
      inactive: count < 1 ? getOccurrenceFor(MODEL_FACET_KEY, name, searchData) === 0 : false,
    };
  });

  const handleModelChange = (value: string, checked: boolean) => {
    const updatedValues = checked ? [...modelIds, value] : modelIds.filter(modelId => modelId !== value);
    if (updatedValues.length) {
      setModelBrand([brandId, ...updatedValues].join(MODEL_BRAND_SEPARATOR));
    } else {
      setModelBrand(null);
    }
  };

  return (
    <FilterBlock
      className="filter__combi"
      count={count}
      remove={modelIds.length ? () => setModelBrand(null) : undefined}
      title={i18next.t("MODEL FILTER")}
    >
      <div className="box">
        <SearchableMultiOptionSelect
          resetOnBlur
          disabled={modelsWithInactive.length === 0}
          fallbackValue={i18next.t("SELECT ALL")}
          label={i18next.t("MODEL TITLE")}
          options={modelsWithInactive}
          values={modelIds.map(modelId => modelMap[modelId])}
          onChange={handleModelChange}
        />
      </div>
    </FilterBlock>
  );
};

type Props = {
  queryParam: string;
  data: FilterReferenceData;
  brandId: number;
  searchData: VehicleSearchResponse | undefined;
};

const ModelFilter: React.FC<Props> = ({ queryParam, data, brandId, searchData }) => {
  const [persistedModelBrands, setModelBrands] = useFilterQueryParam(queryParam, ArrayParam);
  const typedPersistedModelBrands = persistedModelBrands as string[];
  const modelBrand =
    typedPersistedModelBrands && typedPersistedModelBrands.length > 0 ? typedPersistedModelBrands[0] : "";

  return (
    <>
      <FilterBox
        brandId={brandId}
        modelBrandQuery={modelBrand}
        models={data.models}
        searchData={searchData}
        setModelBrand={newModelBrandQuery => {
          if (newModelBrandQuery === null) {
            setModelBrands([]);
          } else {
            setModelBrands([newModelBrandQuery]);
          }
        }}
      />
    </>
  );
};

export default ModelFilter;
