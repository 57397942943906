import { useNavigate } from "react-router-dom";
import { To } from "history";
import { HistoryState } from "../../../router/constants/State";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import useHistoryState from "../../../router/hooks/useHistoryState";

function useProcessTracker({ fallbackPathBuilder }: { fallbackPathBuilder?: () => string } = {}) {
  const { priorSteps } = useHistoryState();

  const navigate = useNavigate();
  const { homePath } = usePathBuilder();

  const getNextStepState = () => {
    return priorSteps ? { [HistoryState.ON_CLOSE_GO_N_BACK]: priorSteps + 1 } : undefined;
  };

  return {
    // used for continuing and going back
    pushProcessStep: (to: To) => {
      navigate(to, {
        state: getNextStepState(),
      });
    },
    closeProcess: () => {
      if (priorSteps) {
        navigate(-priorSteps);
      } else if (fallbackPathBuilder) {
        navigate(fallbackPathBuilder());
      } else {
        navigate(homePath());
      }
    },
    getNextStepState,
    // to be used when using replace to forward to next step, not to be used to read the number of previous steps
    currentStepState: { [HistoryState.ON_CLOSE_GO_N_BACK]: priorSteps },
  };
}

export default useProcessTracker;
