import sessionStorageManager from "../modules/common/global/sessionStorageManager";
import { STORAGE_KEYS } from "../modules/common/constants/Storage";
import { SearchParams } from "../router/constants/SearchParams";

const PREVIEW_MODE_SEARCH_PARAMETER = SearchParams.previewMode;
const PREVIEW_MODE_SEARCH_PARAMETER_VALUE = "on";

export const getIsPreview = () => sessionStorageManager.getItem(STORAGE_KEYS.PREVIEW) === true;
const setIsPreview = (isPreview: boolean) => sessionStorageManager.setItem(STORAGE_KEYS.PREVIEW, isPreview);

export const exitPreview = () => {
  setIsPreview(false);
  window.location.reload();
};

/**
 * Handles preview query mode by setting value in session storage.
 * This should be called before:
 * - any router setup is done to prevent needless re-renderings, and
 * - any state access occurs as it sets values in session storage that won't trigger re-renders
 */
export const initPreviewMode = () => {
  const params = new URLSearchParams(window.location.search);
  const isPreview = params.get(PREVIEW_MODE_SEARCH_PARAMETER) === PREVIEW_MODE_SEARCH_PARAMETER_VALUE;
  if (isPreview) {
    setIsPreview(isPreview);

    params.delete(PREVIEW_MODE_SEARCH_PARAMETER);
    const queryParams = params.toString();
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}${queryParams.length > 0 ? `?${queryParams}` : ""}`,
    );
  }
};
