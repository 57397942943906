import { Ad, AdTargetTile } from "../../hygraph/vo";
import { getHandle } from "../../hygraph/helpers/assetHelpers";
import { IMAGE_PROVIDER_MAGAZINE_BASE_URL } from "../../visual-components/components/Image";
import { Coordinates, isInRadius } from "../../geo/helpers/distance";
import { QueryParams } from "../../vehicle-search/helpers/getQueryStringFromParams";
import {
  BODY_TYPE_QUERY_PARAM,
  BRAND_MODEL_QUERY_PARAM,
  FUEL_TYPE_QUERY_PARAM,
  VEHICLE_CONDITION_QUERY_PARAM,
} from "../../vehicle-search/services/filterDefinition";
import { MODEL_BRAND_SEPARATOR } from "../../vehicle-search/services/filter-types/brandModelFilterTypeHelpers";
import {
  GROUP_SELECT_SEPARATOR,
  TagType,
} from "../../vehicle-search/services/filter-types/groupSelectFilterTypeHelpers";
import { normalizeQueryParam } from "../../vehicle-search/helpers/normalizeQueryParam";

const DEFAULT_MAX_SHARE_RANDOM_RANGE = 100;

export const getImgSrcFromAd = (ad: Ad) => {
  const imageHandleDesktop = getHandle(ad?.imageDesktop?.media);
  const imageHandleMobile = getHandle(ad?.imageMobile?.media);

  return {
    alt: ad?.imageDesktop?.altText ?? ad?.imageMobile?.altText ?? "",
    desktopSrc: imageHandleDesktop ? IMAGE_PROVIDER_MAGAZINE_BASE_URL + imageHandleDesktop : undefined,
    mobileSrc: imageHandleMobile ? IMAGE_PROVIDER_MAGAZINE_BASE_URL + imageHandleMobile : undefined,
  };
};

function filterAdTarget(ad: Ad, adTarget: "AdTargetHero" | "AdTargetTile" | "AdTargetTileHomeRow"): boolean {
  return Boolean(ad.adTarget && ad.adTarget.__typename === adTarget);
}

export function filterAdTargetHero(ad: Ad): boolean {
  return filterAdTarget(ad, "AdTargetHero");
}

export function filterAdTargetTile(ad: Ad): boolean {
  return filterAdTarget(ad, "AdTargetTile");
}

export function filterAdTargetTileHomeRow(ad: Ad): boolean {
  return filterAdTarget(ad, "AdTargetTileHomeRow");
}

export function filterGeoTarget(ad: Ad, referenceLocation: Coordinates | null): boolean {
  const { adTarget } = ad;

  if (!adTarget) {
    return false;
  }
  if (!adTarget.geo) {
    return true;
  }
  if (!referenceLocation) {
    return false;
  }

  return isInRadius(
    { lat: adTarget.geo.center.latitude, lng: adTarget.geo.center.longitude },
    { lat: referenceLocation.lat, lng: referenceLocation.lng },
    adTarget.geo.radius,
  );
}

const QueryParamFilterKeys = ["brands", "models", "bodyTypeGroups", "vehicleConditions", "fuelTypeGroups"] as const;
type QueryParamFilterKey = (typeof QueryParamFilterKeys)[number];

function getAdTargetTile(ad: Ad): AdTargetTile | null {
  if (ad.adTarget && ad.adTarget.__typename === "AdTargetTile") {
    return ad.adTarget;
  }

  return null;
}

export function filterNoQueryParams(ad: Ad): boolean {
  const adTargetTile = getAdTargetTile(ad);
  if (!adTargetTile) {
    return false;
  }

  return QueryParamFilterKeys.every(key => adTargetTile[key].length === 0);
}

export const QueryFilterParamConfig: Record<
  QueryParamFilterKey,
  {
    getAdTargetFilterData: (adTargetTile: AdTargetTile) => string[];
    getQueryParamData: (queryParams: QueryParams) => string[];
  }
> = {
  brands: {
    getAdTargetFilterData: adTargetTile => adTargetTile.brands.map(brand => String(brand.insideId)),
    getQueryParamData: queryParams =>
      normalizeQueryParam(queryParams[BRAND_MODEL_QUERY_PARAM]).map(
        brandModel => brandModel.split(MODEL_BRAND_SEPARATOR)[0],
      ),
  },
  models: {
    getAdTargetFilterData: adTargetTile => adTargetTile.models.map(model => String(model.insideId)),
    getQueryParamData: queryParams =>
      normalizeQueryParam(queryParams[BRAND_MODEL_QUERY_PARAM])
        .map(brandModel => brandModel.split(MODEL_BRAND_SEPARATOR).slice(1))
        .flat(),
  },
  bodyTypeGroups: {
    getAdTargetFilterData: adTargetTile =>
      adTargetTile.bodyTypeGroups.map(bodyTypeGroup => String(bodyTypeGroup.groupId)),
    getQueryParamData: queryParams =>
      normalizeQueryParam(queryParams[BODY_TYPE_QUERY_PARAM])
        .filter(bodyType => bodyType.startsWith(TagType.Group))
        .map(bodyType => bodyType.split(GROUP_SELECT_SEPARATOR)[1]),
  },
  vehicleConditions: {
    getAdTargetFilterData: adTargetTile =>
      adTargetTile.vehicleConditions.map(vehicleCondition => String(vehicleCondition.insideId)),
    getQueryParamData: queryParams => normalizeQueryParam(queryParams[VEHICLE_CONDITION_QUERY_PARAM]),
  },
  fuelTypeGroups: {
    getAdTargetFilterData: adTargetTile =>
      adTargetTile.fuelTypeGroups.map(fuelTypeGroup => String(fuelTypeGroup.groupId)),
    getQueryParamData: queryParams =>
      normalizeQueryParam(queryParams[FUEL_TYPE_QUERY_PARAM])
        .filter(fuelType => fuelType.startsWith(TagType.Group))
        .map(fuelType => fuelType.split(GROUP_SELECT_SEPARATOR)[1]),
  },
};

export const hasIntersection = (a: string[], b: string[]): boolean => {
  const setB = new Set(b);
  return a.some(value => setB.has(value));
};

export function filterQueryParamData(adTargetFilterData: string[], queryParamData: string[]): boolean {
  // No filter criteria in AdTarget means it should pass the filter.
  if (adTargetFilterData.length === 0) {
    return true;
  }

  return hasIntersection(adTargetFilterData, queryParamData);
}

export function filterQueryParam(queryParam: QueryParamFilterKey, ad: Ad, queryParams: QueryParams): boolean {
  const adTargetTile = getAdTargetTile(ad);
  if (!adTargetTile) {
    return false;
  }

  const config = QueryFilterParamConfig[queryParam];

  const adTargetData = config.getAdTargetFilterData(adTargetTile);
  const queryParamData = config.getQueryParamData(queryParams);

  return filterQueryParamData(adTargetData, queryParamData);
}

export function getMaxShares(ads: Ad[]): number[] {
  return ads.map(ad => (ad.adTarget && ad.adTarget?.maxShare ? ad.adTarget?.maxShare : 0));
}

export function accumulateMaxShare(arr: number[]): number[] {
  let sum = 0;
  return arr.map(num => (sum += num));
}

export function getMaxShareRandomRange(accumulatedMaxShare: number[]): number {
  return Math.max(...accumulatedMaxShare) >= DEFAULT_MAX_SHARE_RANDOM_RANGE
    ? Math.max(...accumulatedMaxShare)
    : DEFAULT_MAX_SHARE_RANDOM_RANGE;
}

export function pickRandomAd(ads: Ad[], seed: number): Ad | null {
  // E.g. 3 ads with [100, 100, 50]
  const maxShares: number[] = getMaxShares(ads);
  // E.g. [100, 200, 250] for the example above
  const accumulatedMaxShare: number[] = accumulateMaxShare(maxShares);
  // Expand the random number max range to the accumulated max share.
  const randomNumberMaxRange: number = getMaxShareRandomRange(accumulatedMaxShare);
  // Get random number between 0 and randomNumberMaxRange.
  const randomNumber: number = seed * randomNumberMaxRange;
  // Get the index of the ad based on the random number.
  const index = accumulatedMaxShare.findIndex(share => randomNumber < share);

  return index === -1 ? null : ads[index];
}
