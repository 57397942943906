import { gql } from "graphql-request";

/*
  ${pageNestedSlugFragment}
*/
const linkToUnionFragment = gql`
  fragment LinkToUnion on LinktoUnion {
    ... on BrandLink {
      __typename
      id
      name
      target
      brand {
        slug
      }
    }
    ... on ModelLink {
      __typename
      id
      name
      model {
        slug
        brand {
          slug
        }
      }
    }
    ... on PageLink {
      __typename
      id
      name
      page {
        ...PageNestedSlug
      }
    }
    ... on ArticleLink {
      __typename
      id
      name
      article {
        id
        slug
      }
    }
    ... on DealerLink {
      __typename
      id
      name
      dealer {
        insideId
        slug
      }
      tab
      additionalQueryParameters
    }
    ... on AppLink {
      __typename
      id
      name
      appRoute
      additionalQueryParameters
    }
    ... on MailtoLink {
      __typename
      id
      emailAddress
    }
    ... on TelLink {
      __typename
      id
      phoneNumber
    }
    ... on AbsoluteUrlLink {
      __typename
      id
      openInNewTab
      urlLink {
        url
      }
    }
  }
`;

export default linkToUnionFragment;
