import { dataLayer } from "../../dataLayer";
import { AugmentedDetailVehicle } from "../../../vehicle/types/vehicle";
import { BaseTrackingEvent } from "../../types/BaseTrackingEvent";
import { ECommerce } from "../../types/ECommerce";
import { AttributeConversionToAds } from "../../types/AttributeConversionToAds";
import { getAddToCartTrackEcommerce } from "./getAddToCartTrackEcommerce";

type AddToCartTrackingEvent = BaseTrackingEvent & ECommerce;

export const addToCartTrack = (
  vehicle: AugmentedDetailVehicle | undefined | null,
  attributeConversionToAds: AttributeConversionToAds,
) => {
  if (!vehicle) return false;
  const eventValues = { event: "eec_add_to_cart", event_name: "add_to_cart" };
  const ecommerceTrackingData = getAddToCartTrackEcommerce(vehicle);
  const attributedAds = attributeConversionToAds(vehicle.dealer.insideId, vehicle.brand?.insideId);

  const eventData = {
    ...eventValues,
    attributedAds,
    ...ecommerceTrackingData,
  } satisfies AddToCartTrackingEvent;
  dataLayer([eventData]);
  return true;
};
