import React, { KeyboardEvent, ReactNode, useId, useRef, useState } from "react";
import classNames from "classnames";
import i18next from "i18next";
import useClickAwayHandler from "../../../common/hooks/useClickAwayHandler";
import FormErrorMessage from "../FormErrorMessage";

type Props = {
  label?: string;
  value?: string;
  fallbackValue?: string;
  placeholder?: string;
  search?: {
    value: string;
    setValue: (val: string) => void;
    // boolean indicates whether to close the dropdown or not
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => boolean;
    resetOnBlur?: boolean;
  };
  disabled?: boolean;
  closeOnSelect?: boolean;
  fixOpen?: boolean;
  bg?: boolean;
  autoSize?: boolean;
  // an alternative dropdown indicator
  customDropdownIndicator?: React.ReactNode;
  customLeftIndicator?: React.ReactNode;
  children: ReactNode;
  onClose?: () => void;
  error?: string | undefined;
};

/**
 * Base select to be used in different contexts, do not use directly
 */
const SelectField: React.FC<Props> = ({
  label,
  value,
  // label to display if no values are present
  fallbackValue = "",
  placeholder = "",
  children,
  search,
  customDropdownIndicator,
  customLeftIndicator,
  // todo: handle overflow of text with custom input indicator (currently overflows)
  disabled = false,
  closeOnSelect = false,
  fixOpen = false,
  bg = false,
  autoSize = false,
  onClose,
  error,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const isDropdownOpen = fixOpen || showDropdown;
  const closeDropdown = () => {
    inputRef.current?.blur();
    setShowDropdown(false);
    if (search?.resetOnBlur) {
      search.setValue("");
    }
    onClose?.();
  };
  const dropdownRef = useClickAwayHandler<HTMLDivElement>(() => {
    closeDropdown();
  }, showDropdown);
  const inputRef = useRef<HTMLInputElement>(null);
  const id = useId();

  const openDropdown = () => {
    if (disabled) {
      return;
    }
    inputRef.current?.focus();
    setShowDropdown(true);
  };

  const keyDownHandler: React.KeyboardEventHandler<HTMLInputElement> = e => {
    const close = search?.onKeyDown?.(e) ?? false;
    if (close) {
      closeDropdown();
    }
  };

  let fieldValue = value;
  if (!fieldValue) {
    fieldValue = fallbackValue;
  }
  if (!fieldValue) {
    fieldValue = placeholder;
  }

  return (
    <>
      {label ? (
        <label className="label custom-select__label" htmlFor={id} onClick={openDropdown}>
          {label}
        </label>
      ) : null}
      <div
        className={classNames("custom-select", {
          inactive: disabled,
          "fix-open": fixOpen,
          bg,
          "custom-select--auto-size": autoSize,
        })}
      >
        <div className="custom-select__field" onClick={openDropdown}>
          {customLeftIndicator ? customLeftIndicator : null}
          <div className={classNames("custom-select-field__value", { placeholder: !value && !fallbackValue })}>
            {fieldValue}
          </div>
          {customDropdownIndicator ? (
            customDropdownIndicator
          ) : (
            <span
              aria-label={i18next.t("OPEN DROPDOWN")}
              className="ifont ifont--angle_down custom-select__icon"
              role="button"
            />
          )}
        </div>
        <FormErrorMessage error={error} />
        <div ref={dropdownRef} className={classNames("custom-select__dropdown", { open: showDropdown })}>
          <div className="custom-select-dropdown__field">
            {search ? (
              <input
                ref={inputRef}
                aria-label={i18next.t("SEARCH")}
                className="input custom-select-dropdown-field__input"
                id={id}
                placeholder={i18next.t("SEARCH")}
                type="text"
                value={search.value}
                onKeyDown={search.onKeyDown ? keyDownHandler : undefined}
                onChange={e => {
                  search.setValue(e.target.value);
                }}
              />
            ) : (
              <div className="custom-select-field__value ">{value ? value : fallbackValue}</div>
            )}
            <span
              className="ifont ifont--angle_up custom-select__icon"
              onClick={() => {
                closeDropdown();
              }}
            />
          </div>

          <ul
            className="custom-select-dropdown__list"
            tabIndex={-1}
            onClick={
              closeOnSelect
                ? () => {
                    closeDropdown();
                  }
                : undefined
            }
          >
            {/* do not render children if dropdown is not open */}
            {isDropdownOpen ? children : null}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SelectField;
