import useLocalStorage from "../../common/hooks/useLocalStorage";
import { STORAGE_KEYS } from "../../common/constants/Storage";
import { AdSuccessCriteria } from "../../hygraph/vo";
import { SuccessCriteria } from "../constants/SuccessCriteria";
import { AdClick } from "../types/AdClick";

export const useAttributeConversionToAds = () => {
  const [persistedAds] = useLocalStorage(STORAGE_KEYS.AD_CLICKS, []);

  const matches = (
    successCriteria: AdSuccessCriteria[] | undefined,
    id: number | undefined,
    type: SuccessCriteria,
  ): boolean => {
    return successCriteria?.some(criteria => criteria.insideId === id && criteria.__typename === type) ?? false;
  };

  return (dealerId: number | undefined, brandId: number | undefined): string[] => {
    return persistedAds
      .filter((ad: AdClick) => {
        const dealers = ad.successCriteria[SuccessCriteria.Dealer];
        const brands = ad.successCriteria[SuccessCriteria.Brand];
        return matches(brands, brandId, SuccessCriteria.Brand) || matches(dealers, dealerId, SuccessCriteria.Dealer);
      })
      .map((ad: AdClick) => ad.adCampaign);
  };
};
