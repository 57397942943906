export const BACKEND_API_ENDPOINT = import.meta.env.VITE_BACKEND_ENDPOINT!;

export const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY!;

export const STATIC_ASSETS_ENDPOINT = import.meta.env.VITE_STATIC_ASSETS_ENDPOINT!;

export const ASSETS_ENDPOINT = import.meta.env.VITE_ASSETS_ENDPOINT!;

export const WHITELABEL_ASSETS_ENDPOINT = import.meta.env.VITE_WHITELABEL_ASSETS_ENDPOINT!;

export const FEATURE_FLAG_DARK_MODE = import.meta.env.VITE_FEATURE_FLAG_DARK_MODE === "true";
