import React from "react";
import { dataLayer } from "../../dataLayer";
import { DealerDetail } from "../../../dealer/types/dealer";
import { OutboundClickType } from "../../constants/OutboundClickType";
import { BaseTrackingEvent } from "../../types/BaseTrackingEvent";
import { buildDealerName } from "../dealerTrack/buildDealerName";
import { AugmentedDetailVehicle } from "../../../vehicle/types/vehicle";
import { AttributeConversionToAds } from "../../types/AttributeConversionToAds";
import { DealerResponseItem } from "../../../algolia/services/dealerSearchApi";

export type OutboundTrackingVehicle = Pick<
  AugmentedDetailVehicle,
  "vehicleId" | "model" | "brand" | "title" | "ps" | "priceB2cGross"
>;

type OutboundTrackEvent = {
  dealer: string;
  outbound_links: {
    outbound_link_type: OutboundClickType;
    outbound_link_label: string;
    vehicleId?: number;
    item_name?: string | null;
    item_brand?: string | null;
    item_variant?: string;
    vehicle_ps?: number | null;
    value?: number;
    matelso_subscriber_id?: number | null;
  };
};
export type OutboundTrackingEvent = BaseTrackingEvent & OutboundTrackEvent;

export const outboundClickTrack = ({
  ev,
  dealer,
  outboundLinkType,
  vehicle,
  calltrackingSubscriberId,
  attributeConversionToAds,
}: {
  ev: React.MouseEvent<HTMLSpanElement> | MouseEvent;
  dealer: DealerDetail | DealerResponseItem;
  outboundLinkType: OutboundClickType;
  vehicle?: OutboundTrackingVehicle;
  calltrackingSubscriberId?: number | null;
  attributeConversionToAds: AttributeConversionToAds;
}) => {
  const linkText = (ev.target as HTMLAnchorElement).innerText;
  const attributedAds = attributeConversionToAds(dealer.insideId, vehicle?.brand?.insideId);

  dataLayer([
    {
      event: "gaEvent",
      event_name: "outbound_links",
      dealer: buildDealerName(dealer),
      attributedAds,
      outbound_links: {
        outbound_link_type: outboundLinkType,
        outbound_link_label: linkText,
        vehicleId: vehicle?.vehicleId,
        item_name: vehicle?.model?.name,
        item_brand: vehicle?.brand?.name,
        item_variant: vehicle?.title,
        vehicle_ps: vehicle?.ps,
        value: vehicle?.priceB2cGross,
        matelso_subscriber_id: calltrackingSubscriberId,
      },
    } satisfies OutboundTrackingEvent,
  ]);
};
