import { Ad, AdSuccessCriteria } from "../../hygraph/vo";
import useLocalStorage from "../../common/hooks/useLocalStorage";
import { STORAGE_KEYS } from "../../common/constants/Storage";
import { SuccessCriteria } from "../constants/SuccessCriteria";
import { AdClick } from "../types/AdClick";

export const usePersistAd = (ad: Ad) => {
  const filterSuccessCriteria = (
    type: SuccessCriteria.Brand | SuccessCriteria.Dealer,
    criteria?: AdSuccessCriteria[] | null,
  ) => {
    return criteria?.filter(item => item.__typename === type);
  };

  const timestamp = Date.now();
  const adBrands = filterSuccessCriteria(SuccessCriteria.Brand, ad.successCriteria);
  const adDealers = filterSuccessCriteria(SuccessCriteria.Dealer, ad.successCriteria);

  const [persistedAdClick, setPersistedAdClick] = useLocalStorage<AdClick[]>(STORAGE_KEYS.AD_CLICKS, []);

  const buildEvent = (ad: Ad): AdClick => ({
    successCriteria: {
      Dealer: adDealers,
      Brand: adBrands,
    },
    adCampaign: ad.campaign,
    adId: ad.id,
    adName: ad.name,
    timestamp,
  });

  const builtEvent = buildEvent(ad);

  const addOrUpdateAdClick = () => {
    const updatedAdClicks = persistedAdClick.map(persistedAd => {
      return persistedAd.adId === ad.id ? builtEvent : persistedAd;
    });

    if (!updatedAdClicks.some(persistedAd => persistedAd.adId === ad.id)) {
      updatedAdClicks.push(builtEvent);
    }

    return updatedAdClicks;
  };

  const handlePersist = () => {
    const updatedClicks = addOrUpdateAdClick();
    setPersistedAdClick(updatedClicks);
  };

  return { handlePersist };
};
